var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', [_vm.getAuthMessage.message ? _c('vue-inline-alert', {
    attrs: {
      "message-type": _vm.getAuthMessage.type,
      "message": _vm.getAuthMessage.message
    }
  }) : _vm._e(), _c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|max:27|min:3',
      expression: "'required|max:27|min:3'"
    }],
    attrs: {
      "label": "Name",
      "placeholder": "Your name",
      "filled": "",
      "autofocus": "",
      "id": "signupName",
      "counter": "27"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.veeValidate('Name', 'Name'), false)), _c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    attrs: {
      "label": "Work email",
      "placeholder": "Your work email address",
      "filled": "",
      "id": "signupEmail",
      "type": "email"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }, 'v-text-field', _vm.veeValidate('Email', 'Email'), false)), _c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|max:27|min:3',
      expression: "'required|max:27|min:3'"
    }],
    attrs: {
      "label": "Password",
      "placeholder": "Your password",
      "filled": "",
      "id": "signupPassword",
      "counter": "27",
      "type": "password"
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }, 'v-text-field', _vm.veeValidate('Password', 'Password'), false)), _c('div', {
    staticClass: "pa-6 text-left"
  }, [_vm._v(" By creating an account, you agree to our "), _c('a', {
    attrs: {
      "href": "https://testapp.io/terms-and-conditions",
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._v(" Terms & Conditions ")]), _vm._v(" and "), _c('a', {
    attrs: {
      "href": "https://testapp.io/privacy-policy",
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._v(" Privacy Policy ")])]), _c('v-btn', {
    staticClass: "main primary mx-auto",
    attrs: {
      "loading": _vm.busy,
      "id": "createAccount",
      "disabled": _vm.busy || _vm.errors.any()
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_vm._v(" Create Account ")]), _c('social-auth', {
    attrs: {
      "default-signin": _vm.defaultSignup,
      "button-text": "Sign up with"
    },
    on: {
      "loading": function loading($event) {
        _vm.busy = $event;
      },
      "success": function success($event) {
        return _vm.$emit('success');
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }